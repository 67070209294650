<template>
  <div>
    <v-card class="profilecard-wrap">
      <v-list-item class="pa-0">
        <v-container class="px-0">
          <v-row
            class="px-6 pt-6"
            align="center"
          >
            <v-col
              cols="auto"
              class=""
              style="text-align: right;"
            >
              <img
                :src="getImageUrl(value.extra_info.profile_image) || require(`./assets/illust_${(value.basic_info.gender === 'male') ? 'man' : 'woman'}.png`)"
                :class="{'mobile': ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)}"
                style="width: 100%; max-width: 140px; max-height: 140px; witdh:100%; border-radius: 50%;"
              >
            </v-col>
            <!-- pt-0 -->
            <v-col
              cols="6"
              class="pl-4"
            >
              <v-container>
                <v-row>
                  <v-col class="px-0 pt-0">
                    <span class="text-subtitle-1 font-weight-bold mr-2">{{ value.basic_info.name | nameConv }}</span>
                    <span class="text-body-2">간병사님</span>
                  </v-col>
                </v-row>
                <div
                  v-show="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                  class="pt-6"
                >
                  <v-row align="center">
                    <v-col
                      cols="auto"
                      class="py-1 left-icon-age"
                    >
                      나이
                    </v-col>
                    <v-col class="py-1">
                      <span>{{ value.basic_info.age }}세</span>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      cols="auto"
                      class="py-1 left-icon-gender"
                    >
                      성별
                    </v-col>
                    <v-col class="py-1">
                      <span>{{ convertedGender }}</span>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      cols="auto"
                      class="py-1 left-icon-carrer"
                    >
                      경력
                    </v-col>
                    <v-col class="py-1">
                      <span>{{ value.extra_info.career }}년</span>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-col>
          </v-row>
          <v-row
            v-show="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
            no-gutters
            align="center"
            justify="center"
            class="px-6"
          >
            <v-col
              cols="12"
            >
              <v-container class="pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="space-between"
                  justify-sm="start"
                  style="height: 38px;"
                >
                  <v-col
                    cols="auto"
                    class="px-0 pr-sm-6"
                  >
                    <div class="left-icon-age">
                      <span
                        v-show="!$vuetify.breakpoint.xs"
                        class="pr-2"
                      >나이</span>
                      <span>{{ value.basic_info.age }}세</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="px-0 px-sm-6"
                  >
                    <div class="left-icon-gender">
                      <span
                        v-show="!$vuetify.breakpoint.xs"
                        class="pr-2"
                      >성별</span>
                      <span>{{ convertedGender }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="px-0 pl-sm-6"
                  >
                    <div class="left-icon-carrer">
                      <span
                        v-show="!$vuetify.breakpoint.xs"
                        class="pr-2"
                      >경력</span>
                      <span>{{ value.extra_info.career }}년</span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <v-row
            class="px-6"
            no-gutters
          >
            <v-col
              cols="auto"
              class="pr-4 profileCardLabel"
            >
              자격증
            </v-col>
            <v-col>{{ value.extra_info.license || '-' }}</v-col>
          </v-row>
          <v-row
            class="px-6 pt-3"
            no-gutters
          >
            <v-col
              cols="auto"
              class="pr-4 profileCardLabel"
            >
              지역
            </v-col>
            <v-col>{{ convertedAddress || '-' }}</v-col>
          </v-row>
        </v-container>
      </v-list-item>
      <v-card-actions class="px-6 pb-6">
        <v-container class="px-0 pt-4">
          <v-row>
            <v-col
              cols="6"
              sm="12"
              class="pt-0"
            >
              <v-btn
                outlined
                depressed
                color="#025BBC"
                block
                height="44px"
                @click="$bus.$emit('showCarerDialog', value.extra_info.idx)"
              >
                상세보기
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              sm="12"
              class="pt-0"
            >
              <v-btn
                block
                depressed
                color="#025BBC"
                class="white--text"
                height="44px"
                @click="$bus.$emit('showContactCenterDialog', 'withKakao')"
              >
                문의하기
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { getConvertedAddressText } from '../../models/addressFinder'
import nameConv from './model/filters'

export default {
  filters: {
    nameConv
  },
  // props: ['value']
  props: {
    value: {
      type: Object,
      default() {
        return {
          basic_info: {
            idx: 36,
            email: null,
            phone: "01012356789",
            password: null,
            name: "안중기",
            age: 31,
            gender: "male"
          },
          extra_info: {
            idx: 20,
            license: "요양 보호사",
            career: 11,
            desired_salary: "1",
            office_hour: "anytime",
            introduce: "안녕하세요 열심히 하겠습니다",
            profile_image: "https://image.s3.bucket.com",
            working_area_idx: 16,
            working_area_detail_idx: 252,
            marketing_notification: 1,
            created_at: "2021-06-21T06:41:07",
            updated_at: "2021-06-21T06:41:07"
          },
          work_info: {
            number_of_completions: 0
          }
        }
      }
    }
  },
  data() {
    return {
      convertedGender: 'unknown',
      convertedAddress: '...'
    }
  },
  async created() {
    await this.getGenderTitle()
    await this.getAddressTitle()
  },
  methods: {
    getImageUrl(imageUrl) {
      const isRightUrl = /(https?:\/\/.*\.(?:png|jpg|jpeg))/i.test(imageUrl)
      return isRightUrl ? imageUrl : null
    },
    async getGenderTitle() {
      let convertedGender = 'Unknown'
      if (this.value.basic_info.gender === 'male') {
        convertedGender = '남성'
      } else if (this.value.basic_info.gender === 'female') {
        convertedGender = '여성'
      }
      this.convertedGender = convertedGender
    },
    async getAddressTitle() {
      this.convertedAddress = await getConvertedAddressText(
        this.value.extra_info.working_area_idx,
        this.value.extra_info.working_area_detail_idx
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.profilecard-wrap {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important;
}
.left-icon-age {
  background: url('./assets/icon_cake.png') left center no-repeat;
  background-size: 24px;
  padding-left: 32px !important;
}
.left-icon-gender {
  background: url('./assets/icon_gender.png') left center no-repeat;
  background-size: 24px;
  padding-left: 32px !important;
}
.left-icon-carrer {
  background: url('./assets/icon_carrer.png') left center no-repeat;
  background-size: 24px;
  padding-left: 32px !important;
}
img.mobile {
  width: 48px !important;
  height: 48px !important;
}
.profileCardLabel {
  color: #9E9E9E;
  min-width: 60px;
}

</style>
